import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Pagination,
  Card,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";

export default function RMUsers() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null); // Holds selected agent data
  const [openDetailDialog, setOpenDetailDialog] = useState(false); // Dialog for agent details
  const [refresh, setRefresh] = useState(false); // For refreshing the data

  // Fetch agents data
  useEffect(() => {
    setLoading(true);
    fetch(`/api/admin?offset=${offset * 10}&department=Risk Management`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [offset, refresh]);

  // Search functionality
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(`/api/admin?${column}=${value}&department=Risk Management`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  // Handle row click to view agent details
  const handleRowClick = (agent) => {
    setSelectedAgent(agent);
    setOpenDetailDialog(true);
  };

  return (
    <Box
      mt={9}
      sx={{
        borderRadius: "12px",
        boxShadow: "0px 10px 30px #60606040",
        p: "1em",
      }}
      component={Card}
    >
      <Box sx={{ padding: "0" }}>
        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box sx={{ flexGrow: 1 }}></Box>
          <FormControl>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => setColumn(e.target.value)}
              sx={{ minWidth: "150px" }}
            >
              <MenuItem value="name">Name</MenuItem>
              <MenuItem value="phone">Phone</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <TableContainer sx={{ borderRadius: "12px", mt: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">SN</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.length > 0 ? (
                  data.data.map((agent, index) => (
                    <TableRow
                      key={index}
                      onClick={() => handleRowClick(agent)}
                      hover
                    >
                      <TableCell padding="checkbox">
                        <Chip label={offset * 10 + index + 1} />
                      </TableCell>
                      <TableCell>{agent.Name}</TableCell>
                      <TableCell>{agent.Email}</TableCell>
                      <TableCell>{agent.Phone}</TableCell>
                      <TableCell>{agent.Department}</TableCell>
                      <TableCell>{agent.Position}</TableCell>
                      <TableCell>
                        <Chip
                          color={agent.Status ? "success" : "warning"}
                          label={agent.Status ? "Active" : "Inactive"}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>No Agents Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Box mt={1}>
          {data && (
            <Pagination
              count={Math.ceil(data.total / 10)}
              page={offset + 1}
              onChange={(e, value) => setOffset(value - 1)}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
