import React, { useEffect, useState } from "react";
import {
  Container,
  Grid2,
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  TextField,
} from "@mui/material";
import { BarChart, LineChart, PieChart } from "@mui/x-charts";

export default function RMDashboard() {
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const today = new Date();
  const [start, setStart] = useState(`${today.getFullYear()}-01-01`);
  const [end, setEnd] = useState(today.toISOString().split("T")[0]);
  const [dloading, setDLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchMetrics();
  }, []);

  useEffect(() => {
    if (metrics) {
      setDLoading(true);
      fetchMetrics();
    }
  }, [start, end]);

  const fetchMetrics = async () => {
    try {
      const response = await fetch(
        `/api/loans/risk-management?start=${start}&end=${end}`,
        {
          credentials: "include",
        }
      );
      const data = await response.json();
      setMetrics(data);
    } catch (error) {
    } finally {
      setLoading(false);
      setDLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          mt: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!metrics) {
    return <Typography variant="h5">No data available</Typography>;
  }

  return (
    <Box sx={{ mt: 8 }}>
      <Box sx={{ display: "flex", gap: 2, pt: 2 }}>
        <Typography fontWeight="bold" color="primary" variant="h4" gutterBottom>
          Dashboard
        </Typography>
        <Box flexGrow={1}></Box>
        <TextField
          size="small"
          onChange={(e) => setStart(e.target.value)}
          value={start}
          label="Start"
          type="date"
        />
        <TextField
          size="small"
          onChange={(e) => setEnd(e.target.value)}
          value={end}
          label="End"
          type="date"
        />
      </Box>
      <Box
        sx={{ display: "grid", placeContent: "center", height: "35px", mb: 2 }}
      >
        {dloading && (
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </Box>
      <Grid2 container spacing={3}>
        <CardItem
          title="Total"
          value={
            metrics?.currentLoans
              ? metrics?.currentLoans +
                metrics?.pendingLoans +
                metrics?.rejectedLoans
              : 0
          }
        />
        <CardItem title="Pending" value={metrics?.pendingLoans ?? 0} />
        <CardItem title="Rejected" value={metrics?.rejectedLoans ?? 0} />
        <CardItem title="Active" value={metrics?.currentLoans ?? 0} />

        <Grid2 size={{ xs: 12 }}>
          <Card
            sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
          >
            <CardContent>
              <Typography variant="body1">Loan Amount</Typography>
              <BarChart
                xAxis={[
                  {
                    data: ["Active", "Extended", "Overdue", "Defaulted"],
                    scaleType: "band",
                  },
                ]}
                yAxis={[
                  {
                    valueFormatter: (value) => {
                      if (value >= 1000000) {
                        return `${(value / 1000000).toFixed(0)}m`; // Format values in millions
                      } else if (value >= 1000) {
                        return `${(value / 1000).toFixed(0)}k`; // Format values in thousands
                      }
                      return value; // For values below 1000, return as-is
                    },
                  },
                ]}
                series={[
                  {
                    data: [
                      metrics?.verifiedActiveLoanAmount[0]?.total || 0,
                      metrics?.verifiedPendingLoanAmount[0]?.total || 0,
                      metrics?.verifiedRejectedLoanAmount[0]?.total || 0,
                    ],
                    label: "Premium",
                    color: "blue",
                  },
                  {
                    data: [
                      metrics?.notVerifiedActiveLoanAmount[0]?.total || 0,
                      metrics?.notVerifiedPendingLoanAmount[0]?.total || 0,
                      metrics?.notVerifiedRejectedLoanAmount[0]?.total || 0,
                    ],
                    label: "Regular",
                    color: "orange",
                  },
                ]}
                height={400}
              />
            </CardContent>
          </Card>
        </Grid2>

        <Grid2 size={{ xs: 12 }}>
          <Card
            sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
          >
            <CardContent>
              <Typography variant="body1">Loan Count</Typography>
              <LineChart
                xAxis={[
                  {
                    data: ["Active", "Extended", "Overdue", "Defaulted"],
                    scaleType: "band", // Category-like axis for status labels
                  },
                ]}
                yAxis={[
                  {
                    label: "Loan Count",
                    valueFormatter: (value) => {
                      if (value >= 1000000) {
                        return `${(value / 1000000).toFixed(1)}m`; // Format in millions
                      } else if (value >= 1000) {
                        return `${(value / 1000).toFixed(1)}k`; // Format in thousands
                      }
                      return value; // Raw value for small numbers
                    },
                  },
                ]}
                series={[
                  {
                    data: [
                      metrics?.verifiedActiveLoanAmount[0]?.loanCount || 0,
                      metrics?.verifiedPendingLoanAmount[0]?.loanCount || 0,
                      metrics?.verifiedRejectedLoanAmount[0]?.loanCount || 0,
                    ],
                    label: "Premium",
                    color: "blue",
                  },
                  {
                    data: [
                      metrics?.notVerifiedActiveLoanAmount[0]?.loanCount || 0,
                      metrics?.notVerifiedPendingLoanAmount[0]?.loanCount || 0,
                      metrics?.notVerifiedRejectedLoanAmount[0]?.loanCount || 0,
                    ],
                    label: "Regular",
                    color: "orange",
                  },
                ]}
                height={400}
              />
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </Box>
  );
}

const CardItem = (props) => {
  return (
    <Grid2 size={{ md: 3, xs: 12, sm: 2 }}>
      <Card sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}>
        <CardContent>
          <Typography variant="subtitle" gutterBottom>
            {props.title}
          </Typography>
          <Typography color="primary" variant="h4" mt={2}>
            {props.value.toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>
        </CardContent>
      </Card>
    </Grid2>
  );
};
