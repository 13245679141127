import React, { useEffect, useState } from "react";
import {
  Container,
  Grid2,
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  Stack,
  LinearProgress,
  Divider,
} from "@mui/material";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ArrowForwardIos, LineAxis } from "@mui/icons-material";
import MyPieChart from "../../Pages/Home/MyPieChart";

export default function CSDashboard() {
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [overview, setOverview] = useState({
    customers: {
      total: 0,
      current: 0,
      last: 0,
    },
    payouts: {
      total: 0,
      current: 0,
      last: 0,
    },
    paidin: {
      total: 0,
      current: 0,
      last: 0,
    },
    cs_support: {
      total: 0,
      current: 0,
      last: 0,
      open: 0,
      resolved: 0,
    },
    com: {
      total: 0,
      current: 0,
      last: 0,
      email: 0,
      sms: 0,
    },
    lnp: {
      total: 0,
      current: 0,
      last: 0,
      approved: 0,
      rejected: 0,
    },
    ln_status: [],
  });
  const [customers, setCustomers] = useState({
    users: {
      total: 0,
      current: 0,
      last: 0,
    },
    regular: {
      total: 0,
      current: 0,
      last: 0,
    },
    premium: {
      total: 0,
      current: 3,
      last: 0,
    },
    dormant: {
      total: 0,
      current: 0,
      last: 0,
    },
    genderDistribution: [],
    employmentDistribution: [],
    countyDistribution: [],
    subCountyDistribution: [],
  });
  const [loans, setLoans] = useState({
    principal: {
      total: 0,
      current: 0,
      last: 0,
      repay: 0,
    },
    paid: {
      total: 0,
      current: 0,
      last: 0,
    },
    income: {
      total: 0,
      current: 0,
      last: 0,
    },
    defaulted: {
      total: 0,
      current: 0,
      last: 0,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/loans/overview");
        if (!response.ok) throw new Error("Failed to fetch data");
        const result = await response.json();
        setOverview(result);
      } catch (error) {
        console.error("Error fetching overview data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/users/summary");
        if (!response.ok) throw new Error("Failed to fetch data");
        const result = await response.json();
        setCustomers(result);
      } catch (error) {
        console.error("Error fetching user summary:", error);
      }
    };

    fetchData();
  }, []);



  return (
    <Box sx={{ mt: 8 }}>
      <Typography fontWeight="bold" color="primary" variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid2 container spacing={3}>
        <CardItem
          label="Customers"
          value={overview.customers.total}
          current={overview.customers.current}
          last={overview.customers.last}
          color="#8B5DFF"
          positive={true}
        />
        <CardItem
          label="Issues"
          value={overview.cs_support.total}
          current={overview.cs_support.current}
          last={overview.cs_support.last}
          color="#3D3BF3"
          positive={true}
        />
        <CardItem
          label="Activity"
          value={overview.com.total}
          current={overview.com.current}
          last={overview.com.last}
          color="#219B9D"
          positive={true}
        />{" "}
        <CardItem
          label="Dormant"
          value={customers.dormant.total}
          current={customers.dormant.current}
          last={customers.dormant.last}
          color="#FF8000"
          positive={true}
        />
        <ActivityItem
          label="Customer Support"
          total={overview.cs_support.total}
          l1="Open"
          l2="Resolved"
          v1={overview.cs_support.open}
          v2={overview.cs_support.resolved}
          color="#219B9D"
        />
        <ActivityItem
          label="Loan Processing"
          total={overview.lnp.total}
          l1="Approved"
          l2="Rejected"
          v1={overview.lnp.approved}
          v2={overview.lnp.rejected}
          color="#219B9D"
        />
        <ActivityItem
          label="Communication"
          total={overview.com.total}
          l1="Email"
          l2="SMS"
          v1={overview.com.email}
          v2={overview.com.sms}
          color="#219B9D"
        />
        <Grid2 size={{ xs: 12, md: 4 }}>
          <MyPieChart
            title="Type"
            data={[
              { name: "Premium", value: customers.premium.total },
              { name: "Regular", value: customers.regular.total },
            ]}
            height={200}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4 }}>
          <MyPieChart
            height={200}
            title="Gender"
            data={customers.genderDistribution}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4 }}>
          <MyPieChart
            height={200}
            title="Employment"
            data={customers.employmentDistribution}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <MyLineChart
            title="County Distribution"
            data={customers.countyDistribution}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <MyLineChart
            title="Sub-county Distribution"
            data={customers.subCountyDistribution}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
}

const CardItem = ({ label, value, color, current, last, positive }) => {
  return (
    <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
      <Card sx={{ p: 2, borderRadius: 3, boxShadow: "0px 4px 8px #60606020" }}>
        <Stack spacing={1}>
          <Box display="flex" gap={1}>
            <Typography flexGrow={1}>{label}</Typography>
            <LineAxis sx={{ fontSize: "2em", color: color }} />
          </Box>
          <Typography
            variant="title"
            sx={{ fontWeight: 500, fontSize: "1.5rem", color: color }}
          >
            {(value - 0).toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>
          <Box
            title={`Last Month: ${last}\nThis Month: ${current}`}
            display="flex"
            gap={1}
          >
            <Typography flexGrow={1} sx={{ fontSize: "small" }}>
              vs last month
            </Typography>
            <Typography
              sx={{
                color: `${current >= last ? "green" : "red"}`,
                fontSize: "small",
              }}
            >
              {current + last > 0
                ? ((current / (current + last)) * 100).toFixed(1)
                : "0.0"}
              %
            </Typography>
            <ArrowForwardIos
              sx={{
                color: `${current >= last ? "green" : "red"}`,
                rotate: `${current >= last ? "-" : "+"}90deg`,
                fontSize: "small",
                margin: "auto",
                display: "block",
              }}
            />
          </Box>
        </Stack>
      </Card>
    </Grid2>
  );
};

const MyLineChart = ({ title, data }) => {
  return (
    <Card
      sx={{
        p: 2,
        borderRadius: 3,
        boxShadow: "0px 4px 8px #60606020",
      }}
    >
      <Typography variant="title" sx={{ mb: 2 }}>
        {title || "Line Chart"}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        {data && data.length ? (
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="value" stroke="#82ca9d" />
          </LineChart>
        ) : (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="body2" color="textSecondary">
              No data available
            </Typography>
          </Box>
        )}
      </ResponsiveContainer>
    </Card>
  );
};

const ActivityItem = ({ label, total, l1, l2, v1, v2, color }) => {
  return (
    <Grid2 size={{ xs: 12, md: 4 }}>
      <Box
        sx={{
          p: 2,
          borderRadius: 3,
          border: "1px solid #60606020",
          backgroundColor: "white",
        }}
      >
        <Stack spacing={2}>
          <Box display="flex" gap={1}>
            <Typography flexGrow={1}>{label}</Typography>
          </Box>
          <Divider />
          <Typography
            variant="title"
            sx={{ fontWeight: 500, fontSize: "1.5rem", color: color }}
          >
            {(total - 0).toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1.5fr 4fr",
              position: "relative",
            }}
          >
            <Typography variant="body2" flexGrow={1}>
              {l1}
            </Typography>
            <LinearProgress
              sx={{
                width: "100%",
                height: "24px",
                margin: "auto",
                backgroundColor: "#f5f5f5", // Background color of the track
                "& .MuiLinearProgress-bar": {
                  backgroundColor: color, // Color of the bar
                },
              }}
              variant={"determinate"}
              value={(v1 / total) * 100}
            />
            <Typography
              variant="body2"
              sx={{ position: "absolute", top: "4px", right: "30%" }}
            >
              {(v1 - 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1.5fr 4fr",
              position: "relative",
            }}
          >
            <Typography variant="body2">{l2}</Typography>
            <LinearProgress
              sx={{
                width: "100%",
                height: "24px",
                margin: "auto",
                backgroundColor: "#f5f5f5", // Background color of the track
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#FF8000", // Color of the bar
                },
              }}
              variant={"determinate"}
              value={(v2 / total) * 100}
            />
            <Typography
              variant="body2"
              sx={{ position: "absolute", top: "4px", right: "30%" }}
            >
              {(v2 - 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Grid2>
  );
};