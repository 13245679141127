import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Pagination,
  Card,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import DCAssigned from "./DCassigned";
import DCAgents from "./DCUsers";
import Agents from "./Agents";
import DCUsers from "./DCUsers";

export default function DCUsersHome() {
  const [selected, setSelected] = useState("Supervisors");

  return (
    <Box
      mt={9}
      sx={{
        borderRadius: "12px",
        boxShadow: "0px 10px 30px #60606040",
        p: "1em",
      }}
      component={Card}
    >
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: { xs: 2, md: 1 } }}>
        <Button
          variant={selected === "Supervisors" ? "contained" : "outlined"}
          onClick={() => setSelected("Supervisors")}
          color="primary"
          sx={{ textTransform: "capitalize" }}
        >
          Supervisors
        </Button>
        <Button
          variant={selected === "Assignment" ? "contained" : "outlined"}
          onClick={() => setSelected("Assignment")}
          color="primary"
          sx={{ textTransform: "capitalize" }}
        >
          Management
        </Button>
      </Box>
      {selected == "Supervisors" && <DCUsers />}
      {selected == "Assignment" && <Agents />}
    </Box>
  );
}
