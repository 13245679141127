import React, { useEffect, useState } from "react";
import {
  Grid2,
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  Divider,
  TextField,
  IconButton,
  Tabs,
  Tab,
} from "@mui/material";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import "../App.css";
import LoanAmountChart from "./Home/MyLineChart";
import { CreditCard, Dashboard, Person, Refresh } from "@mui/icons-material";
import Overview from "./Home/Overview";
import Loans from "./Home/Loans";
import Customers from "./Home/Customers";

export default function Home() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ mt: 8 }}>
      <Box display="flex" gap={1}>
        <Typography
          variant="title"
          sx={{ fontSize: "2rem", fontWeight: 500, color: "primary.main" }}
        >
          Dashboard
        </Typography>
        <Box flexGrow={1}></Box>
        <IconButton
          onClick={() => {
            setRefresh(!refresh);
          }}
          sx={{ backgroundColor: "#FDE7BB", height: "44px", width: "44px" }}
        >
          <Refresh />
        </IconButton>
      </Box>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        centered
        textColor="primary"
        indicatorColor="primary"
        sx={{
          mt: 1,
          display: "block",
          width: "fit-content",
          textTransform: "capitalize",
        }}
      >
        <Tab
          icon={<Dashboard />}
          sx={{ textTransform: "capitalize", fontSize: "large" }}
          label="Overview"
        />
        <Tab
          icon={<CreditCard />}
          sx={{ textTransform: "capitalize" }}
          label="Loans"
        />
        <Tab
          icon={<Person />}
          sx={{ textTransform: "capitalize" }}
          label="Customers"
        />
      </Tabs>
      <Divider sx={{ mt: -1 }} />
      <Box mt={2}>{selectedTab === 0 && <Overview refresh={refresh} />}</Box>
      <Box mt={2}>{selectedTab === 1 && <Loans refresh={refresh} />}</Box>
      <Box mt={2}>{selectedTab === 2 && <Customers refresh={refresh} />}</Box>
    </Box>
  );
}
