import React, { useEffect, useState } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  TablePagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import EnquiryDialog from "./EnquiryDialog";
import { Add } from "@mui/icons-material";

export default function CSEnquiries(props) {
  const [enquiries, setEnquiries] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [column, setColumn] = useState("title");
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);

  useEffect(() => {
    fetchEnquiries();
  }, [page, rowsPerPage, column, searchValue]);

  const fetchEnquiries = () => {
    setLoading(true);
    const url = `/api/enquiries?offset=${
      page * rowsPerPage
    }&limit=${rowsPerPage}${searchValue ? `&${column}=${searchValue}` : ""}`;

    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) =>
        res.ok ? res.json() : Promise.reject("Error fetching enquiries")
      )
      .then((data) => {
        setLoading(false);
        setEnquiries(data?.data);
        setCount(data?.total);
      })
      .catch(() => setLoading(false));
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setPage(0);
  };

  const handleStatusUpdate = (enquiryId) => {
    fetch(`/api/enquiries/${enquiryId}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status: true }),
    })
      .then((res) => {
        if (res.ok) {
          setEnquiries((prevEnquiries) =>
            prevEnquiries.map((enq) =>
              enq.ID === enquiryId ? { ...enq, status: true } : enq
            )
          );
        } else throw new Error("Error updating enquiry status");
      })
      .catch((e) => console.error("Failed to update enquiry status:", e));
  };

  const handleRowClick = (enquiry) => {
    setSelectedEnquiry(enquiry);
  };

  const handleCloseDetailsDialog = () => {
    setSelectedEnquiry(null);
  };

  return (
    <Box
      mt={8}
      p={2}
      component={Card}
      sx={{ borderRadius: "12px", boxShadow: "0px 10px 30px #60606040" }}
    >
      <Box display="flex" gap={2} alignItems="center" mb={2}>
        <FormControl>
          <InputLabel size="small">Search by...</InputLabel>
          <Select
            label="Search by..."
            size="small"
            onChange={(e) => setColumn(e.target.value)}
            value={column}
            sx={{ minWidth: "150px" }}
          >
            <MenuItem value="title">Title</MenuItem>
            <MenuItem value="name">Name</MenuItem>
            <MenuItem value="phone">Phone</MenuItem>
            <MenuItem value="category">Category</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          label="Search..."
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpen(true)}
        >
          <Add /> Add Issue
        </Button>
      </Box>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", height: "50vh" }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SN</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {enquiries.length > 0 ? (
                enquiries.map((enquiry, index) => (
                  <TableRow
                    key={enquiry.ID}
                    hover
                    onClick={() => handleRowClick(enquiry)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{enquiry.title}</TableCell>
                    <TableCell>{enquiry.name}</TableCell>
                    <TableCell>{enquiry.phone}</TableCell>
                    <TableCell>{enquiry.category}</TableCell>
                    <TableCell>
                      <Chip
                        label={enquiry.status ? "Resolved" : "Unresolved"}
                        color={enquiry.status ? "success" : "warning"}
                      />
                    </TableCell>
                    <TableCell>
                      {dayjs(enquiry.createdAt).format("YYYY-MM-DD HH:mm")}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent row click
                          handleStatusUpdate(enquiry.ID);
                        }}
                      >
                        Mark as Resolved
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No enquiries found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) =>
          setRowsPerPage(parseInt(event.target.value, 10))
        }
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

      <EnquiryDialog open={open} onClose={() => setOpen(false)} />

      {selectedEnquiry && (
        <Dialog
          open={Boolean(selectedEnquiry)}
          onClose={handleCloseDetailsDialog}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Enquiry Details</DialogTitle>
          <DialogContent>
            <Typography variant="h6">{selectedEnquiry.title}</Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              Category: {selectedEnquiry.category}
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>Message:</strong> {selectedEnquiry.message}
            </Typography>
            {selectedEnquiry.resolution && (
              <Typography variant="body2" paragraph>
                <strong>Resolution:</strong> {selectedEnquiry.resolution}
              </Typography>
            )}
            <Typography variant="body2" color="textSecondary">
              <strong>Submitted By:</strong> {selectedEnquiry.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Phone:</strong> {selectedEnquiry.phone}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Created At:</strong>{" "}
              {dayjs(selectedEnquiry.createdAt).format("YYYY-MM-DD HH:mm")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDetailsDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
