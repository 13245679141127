import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  ButtonGroup,
  Divider,
} from "@mui/material";
import dayjs from "dayjs";

export default function RMLoans() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("firstName");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState("Active");

  // Fetch loans from the API
  useEffect(() => {
    setLoading(true);
    fetch(
      `/api/loans?offset=${
        offset * 10
      }&includeUsers=true&includePayments=true&loanStatus=${
        selected == "Active" ? "Approved" : selected
      }`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [offset, refresh, selected]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(
        `/api/loans?${column}=${value}&includeUsers=true&includePayments=true&loanStatus=${selected}`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  return (
    <Box
      mt={9}
      sx={{
        borderRadius: "12px",
        boxShadow: "0px 10px 30px #60606040",
        p: "1em",
      }}
      component={Card}
    >
      <Box sx={{ padding: "0" }}>
        <ButtonGroup sx={{ mb: 2 }}>
          <Button
            variant={selected === "Active" ? "contained" : "outlined"}
            onClick={() => setSelected("Active")}
            color="success"
            sx={{ textTransform: "capitalize" }}
          >
            Active
          </Button>
          <Button
            variant={selected === "Extension" ? "contained" : "outlined"}
            onClick={() => setSelected("Extension")}
            color="success"
            sx={{ textTransform: "capitalize" }}
          >
            Extension
          </Button>
          <Button
            variant={selected === "Paid" ? "contained" : "outlined"}
            onClick={() => setSelected("Paid")}
            color="primary"
            sx={{ textTransform: "capitalize" }}
          >
            Paid
          </Button>
          <Button
            variant={selected === "Overdue" ? "contained" : "outlined"}
            onClick={() => setSelected("Overdue")}
            color="warning"
            sx={{ textTransform: "capitalize" }}
          >
            Overdue
          </Button>
          <Button
            variant={selected === "Defaulted" ? "contained" : "outlined"}
            onClick={() => setSelected("Defaulted")}
            color="error"
            sx={{ textTransform: "capitalize" }}
          >
            Defaulted
          </Button>
        </ButtonGroup>
        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Box flexGrow={1}>
            <Typography variant="h5">Loans</Typography>
          </Box>
          <FormControl>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => setColumn(e.target.value)}
              value={column}
              sx={{ minWidth: "150px" }}
            >
              <MenuItem value="firstName">First Name</MenuItem>
              <MenuItem value="lastName">Last Name</MenuItem>
              <MenuItem value="idCardNumber">ID No.</MenuItem>
              <MenuItem value="phoneNumber">Phone</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <TableContainer sx={{ borderRadius: "12px", mt: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">SN</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Dates</TableCell>
                  <TableCell>Overdue</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Penalties</TableCell>
                  <TableCell>Paid</TableCell>
                  <TableCell>Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data?.data?.length > 0 ? (
                  data.data.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell padding="checkbox">
                          <Chip label={offset * 10 + index + 1} />
                        </TableCell>
                        <TableCell>
                          {item.User.firstName} {item.User.lastName}
                        </TableCell>
                        <TableCell>
                          {item.User.phoneNumber
                            .replace("+", "")
                            .replace("254", "0")}
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" gutterBottom>
                            Issued:{" "}
                            {dayjs(item.createdAt).format("DD MMM YYYY")}
                          </Typography>
                          <Typography variant="body2">
                            Repayment:
                            {dayjs(item.repaymentDate).format("DD MMM YYYY")}
                          </Typography>
                        </TableCell>
                        <TableCell>{item.overdueDays}</TableCell>
                        <TableCell>
                          <Typography variant="body2" gutterBottom>
                            Principal: {item.amount.toLocaleString()}
                          </Typography>
                          <Typography variant="body2">
                            Interest:
                            {item.interest.toLocaleString()}
                          </Typography>
                        </TableCell>
                        <TableCell>{item.penalties.toLocaleString()}</TableCell>
                        <TableCell>
                          {(
                            item.paymentAmount - item.remainingAmount
                          ).toLocaleString()}
                        </TableCell>
                        <TableCell>
                          <Chip
                            color={
                              item.remainingAmount == 0 ? "success" : "warning"
                            }
                            label={item.remainingAmount.toLocaleString()}
                          ></Chip>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={10}>No Loans Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Box mt={1}>
          {data && (
            <Pagination
              count={Math.ceil(data.total / 10)}
              page={offset + 1}
              onChange={(e, value) => setOffset(value - 1)}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
