import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  ButtonGroup,
  Divider,
  Grid2,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Stack,
} from "@mui/material";
import dayjs from "dayjs";
import {
  ContactEmergency,
  DateRange,
  Emergency,
  Money,
  Person,
  SupportAgent,
} from "@mui/icons-material";
import { CurrencyDollar, User } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

export default function RMApplications(props) {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("firstName");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState("Pending");

  // Fetch loans from the API
  useEffect(() => {
    setLoading(true);
    fetch(
      `/api/loans?offset=${
        offset * 10
      }&includeUsers=true&includePayments=true&includeFollowUps=true&loanStatus=${selected}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [offset, refresh, selected]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(
        `/api/loans?${column}=${value}&includeUsers=true&includePayments=trueincludeFollowUps=true&loanStatus=${selected}`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  return (
    <Box
      mt={9}
      sx={{
        borderRadius: "12px",
        boxShadow: "0px 10px 30px #60606040",
        p: "1em",
      }}
      component={Card}
    >
      <Box sx={{ padding: "0" }}>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: { xs: 2, md: 1 } }}>
          <Button
            variant={selected === "Pending" ? "contained" : "outlined"}
            onClick={() => {
              setOffset(0);
              setSelected("Pending");
            }}
            color="success"
            sx={{ textTransform: "capitalize" }}
          >
            Pending
          </Button>
          <Button
            variant={selected === "Waiting" ? "contained" : "outlined"}
            onClick={() => {
              setOffset(0);
              setSelected("Waiting");
            }}
            color="secondary"
            sx={{ textTransform: "capitalize" }}
          >
            Waiting
          </Button>
          <Button
            variant={selected === "Rejected" ? "contained" : "outlined"}
            onClick={() => {
              setOffset(0);
              setSelected("Rejected");
            }}
            color="error"
            sx={{ textTransform: "capitalize" }}
          >
            Rejected
          </Button>
          <Button
            variant={selected === "Approved" ? "contained" : "outlined"}
            onClick={() => {
              setOffset(0);
              setSelected("Approved");
            }}
            color="primary"
            sx={{ textTransform: "capitalize" }}
          >
            Approved
          </Button>
        </Box>
        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Box flexGrow={1}>
            <Typography variant="h5"></Typography>
          </Box>
          <FormControl>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => setColumn(e.target.value)}
              value={column}
              sx={{ minWidth: "150px" }}
            >
              <MenuItem value="firstName">First Name</MenuItem>
              <MenuItem value="lastName">Last Name</MenuItem>
              <MenuItem value="idCardNumber">ID No.</MenuItem>
              <MenuItem value="phoneNumber">Phone</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <TableContainer sx={{ borderRadius: "12px", mt: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">SN</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Order No</TableCell>
                  <TableCell>Term</TableCell>
                  <TableCell>Repayment</TableCell>
                  <TableCell>Applied</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data?.data?.length > 0 ? (
                  data.data.map((item, index) => {
                    return (
                      <LoanBody
                        key={index}
                        index={index}
                        offset={offset}
                        item={item}
                        selected={selected}
                        refresh={refresh}
                        setRefresh={setRefresh}
                      />
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={10}>No Loans Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Box mt={1}>
          {data && (
            <Pagination
              count={Math.ceil(data.total / 10)}
              page={offset + 1}
              onChange={(e, value) => setOffset(value - 1)}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

const LoanBody = ({ item, index, offset, refresh, setRefresh, selected }) => {
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState("");
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [aloading, setALoading] = useState(false);
  const [error, setError] = useState("");
  const [rloading, setRLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const navigate = useNavigate()

  // Function to update loan status
  const updateLoanStatus = async (loanId, status) => {
    if (status == "Approved") setUpdating(true);
    else setRLoading(true);
    try {
      await fetch(`/api/loans/${loanId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status }),
        credentials: "include",
      });
      setRefresh(!refresh); // Refresh data after update
    } catch (error) {
    } finally {
      setUpdating(false);
      setRLoading(false);
    }
  };

  useEffect(() => {
    if (item) {
      fetch(`/api/users?includeLoans=true&limit=1&id=${item.userId}`, {
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error();
        })
        .then((data) => {
          console.log(data);
          if (data && data.data.length > 0) {
            setUser(data.data[0]);
          }
        })
        .catch((e) => {});
    }
  }, [item, refresh]);

  const handleAssignAgent = () => {
    if (!selectedLoan || !selectedAgentId) return;
    setALoading(true);
    fetch(`/api/loans/${selectedLoan.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ assignedAgentId: selectedAgentId }),
    })
      .then((res) => {
        if (res.ok) {
          setError("Agent assignment succefful!");
          setTimeout(() => {
            setOpen(false);
            setRefresh(!refresh); // Refresh loan data
            setOpenAssignDialog(false); // Close the dialog
          }, 1000);
        } else {
          setError("Agent assignment failed!");
        }
      })
      .catch((error) => {
        setError("Agent assignment failed!");
      })
      .finally(() => {
        setALoading(false);
      });
  };

  return (
    <>
      <TableRow
        onClick={() => {
          navigate(`/rm/applications/${item.id}`)
        }}
        key={index}
      >
        <TableCell padding="checkbox">
          <Chip label={offset * 10 + index + 1} />
        </TableCell>
        <TableCell>
          <Typography variant="body1" gutterBottom>
            {user?.firstName ? user?.firstName : "..."}{" "}
            {user?.lastName ? user?.lastName : "..."}
          </Typography>
        </TableCell>
        <TableCell>
          <Chip
            color={user?.isVerified ? "success" : "warning"}
            label={user?.isVerified ? "Premium" : "Regular"}
          />
        </TableCell>

        <TableCell>
          <Typography variant="body2" gutterBottom>
            {item?.orderNumber}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            {item?.term}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            <Chip label={item?.repaymentDate} />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            <Chip
              label={dayjs(item?.createdAt).format("YYYY MMM, D - HH:mm:ss")}
            />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" gutterBottom>
            <Chip
              color="primary"
              label={
                "KSh " +
                (item.remainingAmount - 0).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }
            />
          </Typography>
        </TableCell>
      </TableRow>
    
    </>
  );
};

const LoanDetailsPopup = ({
  open,
  onClose,
  user,
  updating,
  rloading,
  latestLoan,
  updateLoanStatus,
  selected,
}) => {
  const [overdue, setOverdue] = useState(0);
  const [defaulted, setDefaulted] = useState(null);

  useEffect(() => {
    if (user) {
      setOverdue(checkOverdue(user?.Loans));
      setDefaulted(checkDefaulted(user?.Loans));
    }
  }, [user]);

  const checkOverdue = (loans) => {
    return loans.some((loan) => {
      return loan.status === "Overdue" || loan.overdueDays > 0;
    });
  };

  const checkDefaulted = (loans) => {
    return loans.some((loan) => {
      const repaymentDate = dayjs(loan.repaymentDate);
      const daysSinceRepayment = dayjs().diff(repaymentDate, "day");
      return loan.status === "Defaulted" || daysSinceRepayment > 60;
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ display: "flex", gap: 2 }}>
        <Typography variant="h6" sx={{ margin: "auto" }} flexGrow={1}>
          {user?.firstName} {user?.lastName}
        </Typography>
        <Chip
          color={user?.isVerified ? "success" : "warning"}
          label={user?.isVerified ? "Premium" : "Regular"}
        />
      </DialogTitle>
      <DialogContent dividers>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 2 }}>
            <Person fontSize="large" color="primary" />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 10 }}>
            <Typography variant="body2" gutterBottom></Typography>
            <Typography variant="body2" gutterBottom>
              {user?.phoneNumber}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.alternativePhone}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.email}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Chip size="small" color="warning" label={user?.gender} />
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Chip size="small" label={user?.dateOfBirth} />
            </Typography>
            <Typography
              sx={{ fontSize: "medium", mt: 2 }}
              variant="h6"
              gutterBottom
            >
              Work Details
            </Typography>
            <Divider sx={{ mb: 1 }} />
            <Typography variant="body2" gutterBottom>
              <strong>Employment Type: </strong> {user?.email}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Monthly Income: </strong> {user?.monthlyIncome}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Pay Day: </strong> {user?.payDay}
            </Typography>

            <Typography
              sx={{ fontSize: "medium", mt: 2 }}
              variant="h6"
              gutterBottom
            >
              Location Details
            </Typography>
            <Divider sx={{ mb: 1 }} />
            <Typography variant="body2" gutterBottom>
              <strong>County: </strong> {user?.county}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>SubCounty: </strong> {user?.subcounty}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Ward: </strong> {user?.ward}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Detailed Address: </strong> {user?.detailedAddress}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="title">Emergency Contacts</Typography>
            <Divider />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 2 }}>
            <ContactEmergency color="primary" fontSize="large" />
          </Grid2>
          <Grid2 sx={{ position: "relative" }} size={{ xs: 12, md: 5 }}>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact1?.name}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact1?.contact}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact1?.relationship}
            </Typography>
            <Chip label="1" sx={{ position: "absolute", top: 0, right: 0 }} />
          </Grid2>
          <Grid2 sx={{ position: "relative" }} size={{ xs: 12, md: 5 }}>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact2?.name}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact2?.contact}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {user?.emergencyContact2?.relationship}
            </Typography>
            <Chip label="2" sx={{ position: "absolute", top: 0, right: 0 }} />
          </Grid2>

          <Grid2 size={{ xs: 12 }}>
            <Typography variant="title">Loans</Typography>
            <Divider />
            <Box p={1} component={Stack} gap={1}>
              <Typography variant="body2" flexGrow={1}>
                <strong>Overdue Loans:</strong>{" "}
                <Chip
                  size="small"
                  color={!overdue ? "success" : "error"}
                  label={overdue ? "Yes" : "No"}
                />
              </Typography>
              <Typography variant="body2" flexGrow={1}>
                <strong>Defaulted Loans:</strong>{" "}
                <Chip
                  size="small"
                  color={!defaulted ? "success" : "error"}
                  label={defaulted ? "Yes" : "No"}
                />
              </Typography>
            </Box>
            {user?.Loans?.map((item, index) => {
              return (
                <Card
                  key={index}
                  sx={{
                    boxShadow: "0px 4px 12px #60606030",
                    padding: 1,
                    borderRadius: "8px",
                    display: "flex",
                    gap: 1,
                    mt: 2,
                  }}
                >
                  <Chip size="small" label={item.repaymentDate} />
                  <Typography m={"auto"} variant="body2" flexGrow={1}>
                    KSh{" "}
                    {(item.paymentAmount - 0).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </Typography>

                  <Chip
                    size="small"
                    color={
                      item.status == "Paid" || item.status == "Extension"
                        ? "primary"
                        : item.status == "Pending"
                        ? "success"
                        : "error"
                    }
                    label={item.status}
                  />
                </Card>
              );
            })}
          </Grid2>
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ textTransform: "capitalize" }}
          onClick={onClose}
          variant="outlined"
          color="secondary"
        >
          Close
        </Button>
        {selected == "Pending" && (
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            onClick={() => updateLoanStatus(latestLoan.id, "Approved")}
          >
            {updating ? "Updating..." : "Approve"}
          </Button>
        )}
        {selected == "Pending" && (
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            color="error"
            onClick={() => updateLoanStatus(latestLoan.id, "Rejected")}
          >
            {rloading ? "Updating..." : "Reject"}
          </Button>
        )}
        {(selected == "Rejected" || selected == "Waiting") && (
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            color="warning"
            onClick={() => updateLoanStatus(latestLoan.id, "Pending")}
          >
            {updating ? "Updating..." : "Revoke"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
