import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Stack,
  Divider,
  Alert,
  Grid2,
} from "@mui/material";
import dayjs from "dayjs";

export default function RMCreditLimits() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("firstName");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = useState("All");
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [openChangeLimit, setOpenChangeLimit] = useState(false);
  const [newLimit, setNewLimit] = useState("");
  const [rate, setRate] = useState(0.18);
  const [term, setTerm] = useState(30);
  const [saving, setSaving] = useState(false);

  // Fetch customers from the API
  useEffect(() => {
    setLoading(true);
    setData(null);
    fetch(
      `/api/users?offset=${
        offset * 10
      }&includeLoans=true&includeCreditScore=true${
        status === "All" ? "" : `&status=${status}`
      }`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then((res) => (res.ok ? res.json() : Promise.reject()))
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [offset, refresh, status]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setData(null);
      setLoading(true);
      fetch(
        `/api/users?${column}=${value}&includeLoans=true&includeCreditScore=true${
          status === "All" ? "" : `&status=${status}`
        }`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => (res.ok ? res.json() : Promise.reject()))
        .then((data) => setData(data))
        .catch(() => setData(null))
        .finally(() => {
          setLoading(false);
        });
    } else {
      setRefresh(!refresh);
    }
  };

  const handleOpenDetails = (user) => {
    setSelectedUser(user);
    setOpenDetails(true);
  };

  const handleOpenChangeLimit = (user) => {
    setSelectedUser(user);
    setNewLimit(user.CreditScore?.creditLimit || 0);
    setTerm(user.CreditScore?.term || 7);
    setRate(user.CreditScore?.interestRate || 0.18);
    setOpenChangeLimit(true);
  };

  const handleSaveLimit = () => {
    if (newLimit == "") return Alert("Credit Limit is required!");
    setSaving(true);
    fetch(`/api/creditscores/${selectedUser.id}/manual`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        manualCreditLimit: newLimit,
        creditLimit: newLimit,
        interestRate: rate,
        term: term,
        manualOverride: true,
      }),
    })
      .then((res) => (res.ok ? res.json() : Promise.reject()))
      .then(() => {
        setSaving(false);
        setOpenChangeLimit(false);
        setRefresh(!refresh);
      })
      .catch(() => {
        setSaving(false);
      });
  };

  return (
    <Box
      mt={9}
      sx={{
        borderRadius: "12px",
        boxShadow: "0px 10px 30px #60606040",
        p: "1em",
      }}
      component={Card}
    >
      <Box sx={{ padding: "0" }}>
        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Box flexGrow={1}></Box>
          <FormControl>
            <InputLabel size="small">Type</InputLabel>
            <Select
              label="Type"
              size="small"
              onChange={(e) => {
                setOffset(0);
                setStatus(e.target.value);
              }}
              value={status}
              sx={{ minWidth: "150px" }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="true">Premium</MenuItem>
              <MenuItem value="false">Regular</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => setColumn(e.target.value)}
              value={column}
              sx={{ minWidth: "150px" }}
            >
              <MenuItem value="firstName">First Name</MenuItem>
              <MenuItem value="lastName">Last Name</MenuItem>
              <MenuItem value="idCardNumber">ID No.</MenuItem>
              <MenuItem value="phoneNumber">Phone</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <TableContainer sx={{ borderRadius: "12px", mt: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">SN</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>ID No</TableCell>
                  <TableCell>Loans</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Term</TableCell>
                  <TableCell>Rate</TableCell>
                  <TableCell>Limit</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.length > 0 ? (
                  data.data.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell
                        padding="checkbox"
                        onClick={() => handleOpenDetails(item)}
                      >
                        <Chip label={offset * 10 + index + 1} />
                      </TableCell>
                      <TableCell onClick={() => handleOpenDetails(item)}>
                        {item.firstName} {item.lastName}
                      </TableCell>
                      <TableCell onClick={() => handleOpenDetails(item)}>
                        {item.idCardNumber}
                      </TableCell>
                      <TableCell onClick={() => handleOpenDetails(item)}>
                        {item.Loans.length}
                      </TableCell>
                      <TableCell onClick={() => handleOpenDetails(item)}>
                        <Chip
                          color={item.isVerified ? "success" : "warning"}
                          label={item.isVerified ? "Premium" : "Regular"}
                        />
                      </TableCell>
                      <TableCell onClick={() => handleOpenDetails(item)}>
                        <Chip label={item.CreditScore?.term} />
                      </TableCell>
                      <TableCell onClick={() => handleOpenDetails(item)}>
                        {(item.CreditScore?.interestRate * 100).toFixed(0)}%
                      </TableCell>
                      <TableCell onClick={() => handleOpenDetails(item)}>
                        {(item.CreditScore?.creditLimit || 0).toLocaleString()}
                      </TableCell>
                      <TableCell padding="checkbox">
                        <Button
                          sx={{
                            minWidth: "120px",
                            textTransform: "capitalize",
                          }}
                          variant="outlined"
                          onClick={() => handleOpenChangeLimit(item)}
                        >
                          Change Limit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>No Customers Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Box mt={1}>
          {data && (
            <Pagination
              count={Math.ceil(data.total / 10)}
              page={offset + 1}
              onChange={(e, value) => setOffset(value - 1)}
            />
          )}
        </Box>
      </Box>

      {/* User Details Dialog */}
      {selectedUser && (
        <Dialog
          open={openDetails}
          onClose={() => setOpenDetails(false)}
          fullWidth
        >
          <DialogTitle>User Details</DialogTitle>
          <Divider />
          <DialogContent>
            <Stack spacing={1}>
              <Typography variant="h6">{`${selectedUser.firstName} ${selectedUser.lastName}`}</Typography>
              <Typography variant="body2">
                ID: {selectedUser.idCardNumber}
              </Typography>
              <Typography variant="body2">
                Email: {selectedUser.email}
              </Typography>
              <Typography variant="body2">
                Phone:{" "}
                {selectedUser.phoneNumber.replace("+", "").replace("254", "0")}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Credit Limit:
                {(selectedUser.CreditScore?.creditLimit - 0).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </Typography>
              <Typography sx={{ pt: 2 }} variant="h6" fontSize="medium">
                Recent Loans
              </Typography>
              <Divider />
              {selectedUser.Loans.map((loan, index) => (
                <Card
                  key={index}
                  sx={{
                    mt: 1,
                    mb: 1,
                    boxShadow: "0px 4px 16px #60606040",
                    p: 1,
                  }}
                >
                  <Box display="flex" gap={2}>
                    <Chip
                      sx={{ fontSize: "small" }}
                      label={loan.repaymentDate}
                    />
                    <Typography flexGrow={1} margin="auto">
                      {(loan.paymentAmount - 0).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                    <Typography margin="auto" variant="body2">
                      {loan.overdueDays} days overdue
                    </Typography>
                    <Chip
                      color={loan.status == "Paid" ? "success" : "warning"}
                      label={loan.status}
                    />
                  </Box>
                </Card>
              ))}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDetails(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Change Limit Dialog */}
      <Dialog
        open={openChangeLimit}
        onClose={() => setOpenChangeLimit(false)}
        fullWidth
      >
        <DialogTitle>Change Credit Limit</DialogTitle>
        <DialogContent dividers>
          <Typography>
            Current Limit:{" "}
            <Chip
              label={(
                selectedUser?.CreditScore?.creditLimit - 0
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              color="warning"
            />
          </Typography>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="New Limit"
                type="number"
                fullWidth
                value={newLimit}
                onChange={(e) => setNewLimit(Number(e.target.value))}
                margin="normal"
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 3 }}>
              <TextField
                label="Rate"
                type="number"
                fullWidth
                value={rate}
                onChange={(e) => setRate(Number(e.target.value))}
                margin="normal"
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 3 }}>
              <TextField
                label="Term"
                type="number"
                fullWidth
                value={term}
                onChange={(e) => setTerm(Number(e.target.value))}
                margin="normal"
              />
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenChangeLimit(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleSaveLimit}
            color="primary"
            disabled={saving}
          >
            {saving ? "Updating Loan Limit..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
