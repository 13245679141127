import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Divider,
  Typography,
  IconButton,
  CircularProgress,
  Alert,
} from "@mui/material";
import { Add as AddIcon, Close } from "@mui/icons-material";

export default function Agents() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState("");
  const [dloading, setDLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [agentDetails, setAgentDetails] = useState({
    Name: "",
    Email: "",
    Phone: "",
    NationalID: "",
  });
  const [agents, setAgents] = useState(null);
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);

  // Fetch agents data
  useEffect(() => {
    setLoading(true);
    fetch(`/api/auth?offset=${offset * 10}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [offset, refresh]);

  // Fetch agents data
  useEffect(() => {
    setLoading(true);
    fetch(`/api/admin?department=Debt Collection`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error();
      })
      .then((data) => {
        setAgents(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [offset, refresh]);

  // Search functionality
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(`/api/auth?${column}=${value}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  // Handle row click to view agent details
  const handleRowClick = (agent) => {
    setSelectedAgent(agent);
    setSelectedSupervisor(agent.supervisorId);
    setAgentDetails({
      Name: agent.Name,
      Email: agent.Email,
      Phone: agent.Phone,
      NationalID: agent.NationalID,
    });
    setOpenDetailDialog(true);
  };

  // Handle agent update
  const handleSaveChanges = () => {
    if (agents == null || agents.length == 0) return;
    setUpdateLoading(true);

    fetch(`/api/auth/${selectedAgent.UserID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ supervisorId: selectedSupervisor }),
    })
      .then(async (res) => {
        if (res.ok) return res.json();
        else {
          const errorText = await res.text();
          throw new Error(errorText || "Failed to update agent");
        }
      })
      .then(() => {
        setError("Agent updated successfully!");
        setTimeout(() => {
          setOpenDetailDialog(false);
          setRefresh(!refresh);
          setError("");
        }, 1000);
      })
      .catch((err) => setError(err.message))
      .finally(() => setUpdateLoading(false));
  };

  // Handle agent deactivation
  const handleDeactivateAgent = (status, id) => {
    setDLoading(true);
    fetch(`/api/auth/${id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Status: status,
      }),
    })
      .then(async (res) => {
        if (res.ok) return res.json();
        else throw new Error("Failed to update agent status");
      })
      .then(() => {
        setError("Status updated successfully!");
        setTimeout(() => {
          setOpenDetailDialog(false);
          setRefresh(!refresh);
          setError("");
        }, 1000);
      })
      .catch((err) => setError(err.message))
      .finally(() => setDLoading(false));
  };

  return (
    <Box mt={1}>
      <Box sx={{ padding: "0" }}>
        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box sx={{ flexGrow: 1 }}></Box>
          <FormControl>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => setColumn(e.target.value)}
              sx={{ minWidth: "150px" }}
            >
              <MenuItem value="name">Name</MenuItem>
              <MenuItem value="nationalid">ID No.</MenuItem>
              <MenuItem value="phone">Phone</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <TableContainer sx={{ borderRadius: "12px", mt: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">SN</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>National ID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Supervisor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.length > 0 ? (
                  data.data.map((agent, index) => {
                    return (
                      <ABody
                        agent={agent}
                        index={index}
                        key={index}
                        offset={offset}
                        handleRowClick={handleRowClick}
                      />
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>No Agents Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Box mt={1}>
          {data && (
            <Pagination
              count={Math.ceil(data.total / 10)}
              page={offset + 1}
              onChange={(e, value) => setOffset(value - 1)}
            />
          )}
        </Box>
      </Box>

      {/* Agent Detail Dialog */}
      {selectedAgent && (
        <Dialog
          open={openDetailDialog}
          onClose={() => setOpenDetailDialog(false)}
          fullWidth
        >
          <DialogTitle sx={{ display: "flex" }}>
            <Typography variant="h6" sx={{ margin: "auto" }} flexGrow={1}>
              Assign Supervisor
            </Typography>
            <IconButton onClick={() => setOpenDetailDialog(false)}>
              <Close />
            </IconButton>
          </DialogTitle>
          <Divider sx={{ mb: "5px" }} />
          <DialogContent>
            <Stack spacing={2}>
              <FormControl fullWidth>
                <InputLabel>Supervisor</InputLabel>
                <Select
                  label="Supervisor"
                  value={selectedSupervisor}
                  onChange={(e) => setSelectedSupervisor(e.target.value)}
                >
                  {agents.data.map((agent) => (
                    <MenuItem key={agent.UserID} value={agent.UserID}>
                      {agent.Name} - {agent.Phone}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {error && (
                <Alert
                  color={error.includes("success") ? "success" : "warning"}
                >
                  {error}
                </Alert>
              )}
            </Stack>
          </DialogContent>
          <Divider sx={{ my: "5px" }} />
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              sx={{ color: "white", textTransform: "capitalize" }}
              onClick={() =>
                handleDeactivateAgent(
                  !selectedAgent.Status,
                  selectedAgent.UserID
                )
              }
            >
              {dloading
                ? "Updating..."
                : selectedAgent.Status
                ? "Deactivate"
                : "Activate"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ color: "white", textTransform: "capitalize" }}
              onClick={handleSaveChanges}
            >
              {updateLoading ? "Saving Changes..." : "Save Changes"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

const ABody = ({ agent, index, offset, handleRowClick }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(null);

  // Fetch agents data
  useEffect(() => {
    if (agent.supervisorId) {
      setLoading(true);
      fetch(`/api/admin?limit=1&id=${agent.supervisorId}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          if (data.data.length > 0) {
            setUser(data.data[0]);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [agent]);

  return (
    <TableRow key={index} onClick={() => handleRowClick(agent)} hover>
      <TableCell padding="checkbox">
        <Chip label={offset * 10 + index + 1} />
      </TableCell>
      <TableCell>{agent.Name}</TableCell>
      <TableCell>{agent.Email}</TableCell>
      <TableCell>{agent.Phone}</TableCell>
      <TableCell>{agent.NationalID}</TableCell>
      <TableCell>
        <Chip
          color={agent.Status ? "success" : "warning"}
          label={agent.Status ? "Active" : "Inactive"}
        />
      </TableCell>
      <TableCell>{loading ? "Loading..." : user ? user.Name : "..."}</TableCell>
    </TableRow>
  );
};
