import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Collapse,
  Divider,
  Stack,
  Chip,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

export default function Loans({ user }) {
  const [expandedLoanId, setExpandedLoanId] = useState(null);
  console.log(user);

  const handleExpandClick = (loanId) => {
    setExpandedLoanId((prevId) => (prevId === loanId ? null : loanId));
  };

  return (
    <Box>
      <Typography variant="h5" mb={2}>
        Loans
      </Typography>
      <Stack spacing={2}>
        {user.Loans.map((loan) => (
          <Card
            key={loan.id}
            sx={{
              position: "relative",
              boxShadow: "0px 4px 16px #60606040",
              borderRadius: "8px",
            }}
          >
            <CardContent>
              <Typography variant="title">
                Order Number: {loan.orderNumber}
              </Typography>
              <Typography variant="body2">
                <strong>Amount:</strong> KSh {loan.amount}
              </Typography>
              <Typography variant="body2">
                <strong>Repayment Date:</strong> {loan.repaymentDate}
              </Typography>
              <Typography variant="body2">
                <strong>Remaining Amount:</strong> KSh {loan.remainingAmount}
              </Typography>
              <Chip
                label={loan.status}
                sx={{ position: "absolute", top: "1em", right: "1em" }}
                color={
                  loan.status === "Pending"
                    ? "warning"
                    : loan.status === "Paid"
                    ? "success"
                    : "default"
                }
              />
              <Button size="small" onClick={() => handleExpandClick(loan.id)}>
                {expandedLoanId === loan.id ? "Hide Details" : "View Details"}
              </Button>
            </CardContent>
            <Collapse
              in={expandedLoanId === loan.id}
              timeout="auto"
              unmountOnExit
            >
              <CardContent>
                {/* Follow Ups Section */}
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  Follow Ups
                </Typography>
                <Divider />
                {loan.LoanFollowUps && loan.LoanFollowUps.length > 0 ? (
                  <List>
                    {loan.LoanFollowUps.map((followup, index) => (
                      <ListItem
                        sx={{
                          border: "1px solid #60606030",
                          borderRadius: 2,
                          mb: 1,
                        }}
                        key={index}
                      >
                        <ListItemText
                          primary={`${followup.feedback}`}
                          secondary={`Description: ${followup.feedbackReason}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    No follow-ups for this loan.
                  </Typography>
                )}

                {/* Payments Section */}
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  Payments
                </Typography>
                <Divider />
                {loan.payments && loan.payments.length > 0 ? (
                  <List>
                    {loan.payments.map((payment, index) => (
                      <ListItem
                        sx={{
                          border: "1px solid #60606030",
                          borderRadius: 2,
                          mb: 1,
                        }}
                        key={index}
                      >
                        <ListItemText
                          primary={`Amount Paid: KSh ${payment.amountPaid}`}
                          secondary={`Payment Date: ${payment.paymentDate}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    No payments for this loan.
                  </Typography>
                )}
              </CardContent>
            </Collapse>
          </Card>
        ))}
      </Stack>
    </Box>
  );
}
