import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Box,
  Alert,
  Divider,
} from "@mui/material";

export default function ForgotPassword(props) {
  const email = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [body, updateBody] = useState({ Email: null });

  useEffect(() => {
    setMessage("");
  }, [props.open]);

  const resetPassword = async () => {
    let d = body;
    d.Email = email.current.value;
    updateBody(d);
    setMessage("");

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    if (!validateEmail(body.Email)) {
      return setMessage("Please provide a valid email address");
    }

    if (validateEmail(body.Email)) {
      setIsLoading(true);
      const response = await fetch("/api/admin/forgot", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ Email: email.current.value }),
      });
      setIsLoading(false);
      const body = await response.json();
      if (response.ok) {
        setMessage(body.message);
        setTimeout(() => {
          props.showForgotPassword(false);
        }, 1000);
      } else setMessage(body?.message ?? "Connection to server failed!");
    }
  };

  return (
    <Dialog
      fullWidth="md"
      open={props.open}
      onClose={() => props.showForgotPassword(false)}
    >
      <DialogTitle>Forgot Password</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          We'll generate a password and send to this email
        </Typography>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            resetPassword();
          }}
        >
          <TextField
            inputRef={email}
            type="email"
            label="Your email"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            gutterBottom
          />
        </form>

        {message && (
          <Alert severity={message.includes("success") ? "success" : "error"}>
            {message}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.showForgotPassword(false)}
          color="secondary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={resetPassword}
          variant="contained"
          color="primary"
          disabled={isLoading}
          sx={{ textTransform: "capitalize" }}
          fullWidth
        >
          {isLoading ? "Reseting..." : "Reset"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
