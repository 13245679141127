import React, { useEffect, useState } from "react";
import {
  ArrowForwardIos,
  LineAxis,
  LineAxisOutlined,
  MoneyOff,
  People,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid2,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Activity } from "@phosphor-icons/react";
import MyPieChart from "./MyPieChart";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default function Customers({ refresh }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setData(null);
      try {
        const response = await fetch("/api/users/summary");
        if (!response.ok) throw new Error("Failed to fetch data");
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching user summary:", error);
      }
    };

    fetchData();
  }, [refresh]);

  if (!data) {
    return (
      <Box sx={{ height: "70vh", display: "grid", placeContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid2 container spacing={3}>
      <CardItem
        label="Total"
        value={data.users.total}
        current={data.users.current}
        last={data.users.last}
        color="#8B5DFF"
        positive={true}
      />
      <CardItem
        label="Regular"
        value={data.regular.total}
        current={data.regular.current}
        last={data.regular.last}
        color="#3D3BF3"
        positive={true}
      />
      <CardItem
        label="Premium"
        value={data.premium.total}
        current={data.premium.current}
        last={data.premium.last}
        color="#219B9D"
        positive={true}
      />
      <CardItem
        label="Dormant"
        value={data.dormant.total}
        current={data.dormant.current}
        last={data.dormant.last}
        color="#219B9D"
        positive={true}
      />
      <Grid2 size={{ xs: 12, md: 4 }}>
        <MyPieChart
          title="Type"
          data={[
            { name: "Premium", value: data.premium.total },
            { name: "Regular", value: data.regular.total },
          ]}
          height={200}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4 }}>
        <MyPieChart
          height={200}
          title="Gender"
          data={data.genderDistribution}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4 }}>
        <MyPieChart
          height={200}
          title="Employment"
          data={data.employmentDistribution}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 6 }}>
        <MyLineChart
          height={200}
          title="County Distribution"
          data={data.countyDistribution}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 6 }}>
        <MyLineChart
          title="Sub-county Distribution"
          data={data.subCountyDistribution}
        />
      </Grid2>
    </Grid2>
  );
}

const CardItem = ({ label, value, color, current, last, positive }) => {
  return (
    <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
      <Card
        sx={{
          p: 2,
          borderRadius: 3,
          border: `1px solid ${color}`,
          color: color,
          boxShadow: "0px 4px 8px #60606020",
        }}
      >
        <Stack spacing={1}>
          <Box display="flex" gap={1}>
            <Typography flexGrow={1}>{label}</Typography>
            <People sx={{ fontSize: "2em" }} />
          </Box>
          <Typography
            variant="title"
            sx={{ fontWeight: 500, fontSize: "1.5rem" }}
          >
            {(value - 0).toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>
          <Box
            title={`Last Month: ${last}\nThis Month: ${current}`}
            display="flex"
            gap={1}
          >
            <Typography flexGrow={1} sx={{ fontSize: "small" }}>
              vs last month
            </Typography>
            <Typography
              sx={{
                color: `${current >= last ? "green" : "red"}`,
                fontSize: "small",
              }}
            >
              {current + last > 0
                ? ((current / (current + last)) * 100).toFixed(1)
                : "0.0"}
              %
            </Typography>
            <ArrowForwardIos
              sx={{
                color: `${current >= last ? "green" : "red"}`,
                rotate: `${current >= last ? "-" : "+"}90deg`,
                fontSize: "small",
                margin: "auto",
                display: "block",
              }}
            />
          </Box>
        </Stack>
      </Card>
    </Grid2>
  );
};

const MyLineChart = ({ title, data }) => {
  return (
    <Card
      sx={{
        p: 2,
        borderRadius: 3,
        boxShadow: "0px 4px 8px #60606020",
      }}
    >
      <Typography variant="title" sx={{ mb: 2 }}>
        {title || "Line Chart"}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        {data && data.length ? (
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="value" stroke="#82ca9d" />
          </LineChart>
        ) : (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="body2" color="textSecondary">
              No data available
            </Typography>
          </Box>
        )}
      </ResponsiveContainer>
    </Card>
  );
};
