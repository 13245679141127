import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  IconButton,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Stack,
  DialogActions,
  Button,
  Snackbar,
  Checkbox,
  FormControlLabel,
  Alert,
} from "@mui/material";
import { Message, Send } from "@mui/icons-material";
import dayjs from "dayjs";

export default function CSCustomers() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState("firstName");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = useState("All");
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [messageTitle, setMessageTitle] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [sendSMS, setSendSMS] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [bulkSend, setBulkSend] = useState(false);


  // Fetch customers from the API
  useEffect(() => {
    setLoading(true);
    fetch(
      `/api/users?offset=${
        offset * 10
      }&includeLoans=true&includeCreditScore=true${
        status === "All" ? "" : `&status=${status}`
      }`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then((res) => (res.ok ? res.json() : Promise.reject()))
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [offset, refresh, status]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      fetch(
        `/api/users?${column}=${value}&includeLoans=true&includeCreditScore=true${
          status === "All" ? "" : `&status=${status}`
        }`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => (res.ok ? res.json() : Promise.reject()))
        .then((data) => setData(data))
        .catch(() => setData(null));
    } else {
      setRefresh(!refresh);
    }
  };

  const handleOpenMessageDialog = (user = null) => {
    setSelectedUser(user);
    setBulkSend(!user); // If no user, it's a bulk send
    setOpenMessageDialog(true);
  };

  const handleCloseMessageDialog = () => {
    setMessageTitle("");
    setMessageContent("");
    setSendSMS(false);
    setSendEmail(false);
    setOpenMessageDialog(false);
  };

  const handleSendMessage = () => {
    const messageData = {
      Subject: messageTitle,
      Message: messageContent,
      SMS: sendSMS,
      Email: sendEmail,
    };

    // If bulk send is enabled, send to all filtered users
    if (bulkSend) {
      data.data.forEach((user) => {
        sendMessageToUser(user.id, messageData);
      });
    } else {
      sendMessageToUser(selectedUser.id, messageData);
    }
  };

  const sendMessageToUser = (userId, messageData) => {
    fetch(`/api/messages`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...messageData, userId }),
    })
      .then(async (res) => {
        if (res.ok) {
          setSnackbarMessage("Message sent successfully!");
          setSnackbarOpen(true);
          setTimeout(() => {
            handleCloseMessageDialog();
          }, 1000);
        } else {
          const body = await res.json();
          setSnackbarMessage(body.message);
          setSnackbarOpen(true);
        }
      })
      .catch(() => {
        setSnackbarMessage("Failed to send message.");
        setSnackbarOpen(true);
      });
  };

  return (
    <Box
      mt={9}
      sx={{
        borderRadius: "12px",
        boxShadow: "0px 10px 30px #60606040",
        p: "1em",
      }}
      component={Card}
    >
      <Box sx={{ padding: "0" }}>
        <Box display="flex" gap={2} alignItems="center" mb={2}>
          <Typography flexGrow={1} variant="h5">
            Customer Support
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Send />}
            onClick={() => handleOpenMessageDialog()}
          >
            Send Bulk Message
          </Button>

          <Box
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <FormControl>
              <InputLabel size="small">Type</InputLabel>
              <Select
                label="Type"
                size="small"
                onChange={(e) => {
                  setOffset(0);
                  setStatus(e.target.value);
                }}
                value={status}
                sx={{ minWidth: "150px" }}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="true">Premium</MenuItem>
                <MenuItem value="false">Regular</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel size="small">Search by...</InputLabel>
              <Select
                label="Search by..."
                size="small"
                onChange={(e) => setColumn(e.target.value)}
                value={column}
                sx={{ minWidth: "150px" }}
              >
                <MenuItem value="firstName">First Name</MenuItem>
                <MenuItem value="lastName">Last Name</MenuItem>
                <MenuItem value="idCardNumber">ID No.</MenuItem>
                <MenuItem value="phoneNumber">Phone</MenuItem>
                <MenuItem value="email">Email</MenuItem>
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              size="small"
              label="Search..."
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Box>
        </Box>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <TableContainer sx={{ borderRadius: "12px", mt: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">SN</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>ID No</TableCell>
                  <TableCell>Loans</TableCell>
                  <TableCell>Credit Limit</TableCell>
                  <TableCell>Send Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.length > 0 ? (
                  data.data.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell padding="checkbox">
                        <Chip label={offset * 10 + index + 1} />
                      </TableCell>
                      <TableCell>
                        <Chip
                          color={item.isVerified ? "success" : "warning"}
                          label={item.isVerified ? "Premium" : "Regular"}
                        />
                      </TableCell>
                      <TableCell>
                        {item.firstName} {item.lastName}
                      </TableCell>
                      <TableCell>{item.idCardNumber}</TableCell>
                      <TableCell>{item.Loans.length}</TableCell>
                      <TableCell>
                        {(item.CreditScore?.creditLimit || 0).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleOpenMessageDialog(item)}
                        >
                          <Message />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>No Customers Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Dialog
          open={openMessageDialog}
          onClose={handleCloseMessageDialog}
          fullWidth
        >
          <DialogTitle>
            {bulkSend
              ? "Send Bulk Message"
              : `Send Message to ${selectedUser?.firstName}`}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <TextField
              label="Title"
              fullWidth
              variant="outlined"
              margin="normal"
              value={messageTitle}
              onChange={(e) => setMessageTitle(e.target.value)}
              required
            />
            <TextField
              label="Message"
              fullWidth
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
              value={messageContent}
              onChange={(e) => setMessageContent(e.target.value)}
              required
            />
            <Stack direction="row" spacing={2} mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sendSMS}
                    onChange={(e) => setSendSMS(e.target.checked)}
                  />
                }
                label="Send via SMS"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sendEmail}
                    onChange={(e) => setSendEmail(e.target.checked)}
                  />
                }
                label="Send via Email"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMessageDialog} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleSendMessage}
              color="primary"
              variant="contained"
            >
              Send Message
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert severity="success">{snackbarMessage}</Alert>
        </Snackbar>

        <Box mt={2}>
          {data && (
            <Pagination
              count={Math.ceil(data.total / 10)}
              page={offset + 1}
              onChange={(e, value) => setOffset(value - 1)}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
