import React, { useEffect, useState } from "react";
import { ArrowForwardIos, LineAxis } from "@mui/icons-material";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid2,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Activity } from "@phosphor-icons/react";
import MyLineChart from "./MyLineChart";
import MyPieChart from "./MyPieChart";

export default function Overview({ refresh }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setData(null);
      try {
        const response = await fetch("/api/loans/overview");
        if (!response.ok) throw new Error("Failed to fetch data");
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching overview data:", error);
      }
    };

    fetchData();
  }, [refresh]);

  if (!data) {
    return (
      <Box sx={{ height: "70vh", display: "grid", placeContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid2 container spacing={3}>
      <CardItem
        label="Customers"
        value={data.customers.total}
        current={data.customers.current}
        last={data.customers.last}
        color="#8B5DFF"
        positive={true}
      />
      <CardItem
        label="Payouts"
        value={data.payouts.total}
        current={data.payouts.current}
        last={data.payouts.last}
        color="#3D3BF3"
        positive={true}
      />
      <CardItem
        label="Paid In"
        value={data.paidin.total}
        current={data.paidin.current}
        last={data.paidin.last}
        color="#219B9D"
        positive={true}
      />
      <CardItem
        label="Profits"
        value={data.paidin.total - data.payouts.total}
        current={data.paidin.current - data.payouts.current}
        last={data.paidin.last - data.payouts.last}
        color="#FF8000"
        positive={data.paidin.current >= data.payouts.last}
      />
      <Grid2 size={{ xs: 12, md: 8 }}>
        <MyLineChart />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4 }}>
        <MyPieChart data={data.ln_status} height={370} />
      </Grid2>
      <ActivityItem
        label="Customer Support"
        total={data.cs_support.total}
        l1="Open"
        l2="Resolved"
        v1={data.cs_support.open}
        v2={data.cs_support.resolved}
        color="#219B9D"
      />
      <ActivityItem
        label="Loan Processing"
        total={data.lnp.total}
        l1="Approved"
        l2="Rejected"
        v1={data.lnp.approved}
        v2={data.lnp.rejected}
        color="#219B9D"
      />
      <ActivityItem
        label="Communication"
        total={data.com.total}
        l1="Email"
        l2="SMS"
        v1={data.com.email}
        v2={data.com.sms}
        color="#219B9D"
      />
    </Grid2>
  );
}

const CardItem = ({ label, value, color, current, last, positive }) => {
  return (
    <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
      <Card sx={{ p: 2, borderRadius: 3, boxShadow: "0px 4px 8px #60606020" }}>
        <Stack spacing={1}>
          <Box display="flex" gap={1}>
            <Typography flexGrow={1}>{label}</Typography>
            <LineAxis sx={{ fontSize: "2em", color: color }} />
          </Box>
          <Typography
            variant="title"
            sx={{ fontWeight: 500, fontSize: "1.5rem", color: color }}
          >
            {(value - 0).toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>
          <Box
            title={`Last Month: ${last}\nThis Month: ${current}`}
            display="flex"
            gap={1}
          >
            <Typography flexGrow={1} sx={{ fontSize: "small" }}>
              vs last month
            </Typography>
            <Typography
              sx={{
                color: `${current >= last ? "green" : "red"}`,
                fontSize: "small",
              }}
            >
              {current + last > 0
                ? ((current / (current + last)) * 100).toFixed(1)
                : "0.0"}
              %
            </Typography>
            <ArrowForwardIos
              sx={{
                color: `${current >= last ? "green" : "red"}`,
                rotate: `${current >= last ? "-" : "+"}90deg`,
                fontSize: "small",
                margin: "auto",
                display: "block",
              }}
            />
          </Box>
        </Stack>
      </Card>
    </Grid2>
  );
};

const ActivityItem = ({ label, total, l1, l2, v1, v2, color }) => {
  return (
    <Grid2 size={{ xs: 12, md: 4 }}>
      <Box
        sx={{
          p: 2,
          borderRadius: 3,
          border: "1px solid #60606020",
          backgroundColor: "white",
        }}
      >
        <Stack spacing={2}>
          <Box display="flex" gap={1}>
            <Typography flexGrow={1}>{label}</Typography>
          </Box>
          <Divider />
          <Typography
            variant="title"
            sx={{ fontWeight: 500, fontSize: "1.5rem", color: color }}
          >
            {(total - 0).toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1.5fr 4fr",
              position: "relative",
            }}
          >
            <Typography variant="body2" flexGrow={1}>
              {l1}
            </Typography>
            <LinearProgress
              sx={{
                width: "100%",
                height: "24px",
                margin: "auto",
                backgroundColor: "#f5f5f5", // Background color of the track
                "& .MuiLinearProgress-bar": {
                  backgroundColor: color, // Color of the bar
                },
              }}
              variant={"determinate"}
              value={(v1 / total) * 100}
            />
            <Typography
              variant="body2"
              sx={{ position: "absolute", top: "4px", right: "30%" }}
            >
              {(v1 - 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1.5fr 4fr",
              position: "relative",
            }}
          >
            <Typography variant="body2">{l2}</Typography>
            <LinearProgress
              sx={{
                width: "100%",
                height: "24px",
                margin: "auto",
                backgroundColor: "#f5f5f5", // Background color of the track
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#FF8000", // Color of the bar
                },
              }}
              variant={"determinate"}
              value={(v2 / total) * 100}
            />
            <Typography
              variant="body2"
              sx={{ position: "absolute", top: "4px", right: "30%" }}
            >
              {(v2 - 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Grid2>
  );
};
