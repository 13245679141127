import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Grid2,
  ButtonGroup,
  Button,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const MyLineChart = ({ title }) => {
  const [type, setType] = useState("1 Year");
  const [selectedStatus, setSelectedStatus] = useState(""); // Default status (empty means all)
  const [chartData, setChartData] = useState([]); // Store combined data for LineChart
  const [loading, setLoading] = useState(false); // To manage loading state

  // Function to fetch data for a specific field and map it to common format
  const fetchDataForField = async (field) => {
    const statusParam = selectedStatus ? `&status=${selectedStatus}` : "";
    const response = await fetch(
      `/api/loans/loan-summary?field=${field}${statusParam}&period=${type}`
    );
    if (!response.ok) throw new Error(`Error fetching ${field} data`);

    const data = await response.json();
    return data.map((item) => ({ name: item.name, [field]: item.value }));
  };

  // Function to fetch all fields and combine data for LineChart
  const fetchAllData = async () => {
    setLoading(true);
    try {
      const [amount, interest, penalties, paymentAmount] = await Promise.all([
        fetchDataForField("amount"),
        fetchDataForField("interest"),
        fetchDataForField("penalties"),
        fetchDataForField("paymentAmount"),
      ]);

      // Combine data by matching labels
      const combinedData = amount.map((item, index) => ({
        name: item.name,
        amount: item.amount,
        interest: interest[index]?.interest,
        penalties: penalties[index]?.penalties,
        paymentAmount: paymentAmount[index]?.paymentAmount,
      }));

      console.log(combinedData);

      setChartData(combinedData);
    } catch (error) {
      console.error("Error fetching loan summary data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data whenever selectedStatus changes
  useEffect(() => {
    fetchAllData();
  }, [selectedStatus, type]);

  return (
    <Card sx={{ borderRadius: 3, boxShadow: "0px 4px 8px #60606030" }}>
      <CardContent sx={{ position: "relative" }}>
        <Box display="flex" gap={1}>
          <Typography flexGrow={1} variant="title" gutterBottom>
            {title ?? "Overview"}
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              onClick={() => {
                setType("1 Year");
              }}
              size="small"
              sx={{
                textTransform: "capitalize",
                borderRadius: "32px",
                fontSize: "x-small",
              }}
              variant={type == "1 Year" ? "contained" : "outlined"}
            >
              1 Year
            </Button>
            <Button
              onClick={() => {
                setType("6 Months");
              }}
              size="small"
              sx={{
                textTransform: "capitalize",
                borderRadius: "32px",
                fontSize: "x-small",
              }}
              variant={type == "6 Months" ? "contained" : "outlined"}
            >
              6 Months
            </Button>
            <Button
              onClick={() => {
                setType("3 Months");
              }}
              size="small"
              sx={{
                textTransform: "capitalize",
                borderRadius: "32px",
                fontSize: "x-small",
              }}
              variant={type == "3 Months" ? "contained" : "outlined"}
            >
              3 Months
            </Button>
            <Button
              onClick={() => {
                setType("1 Month");
              }}
              size="small"
              sx={{
                textTransform: "capitalize",
                borderRadius: "32px",
                fontSize: "x-small",
              }}
              variant={type == "1 Month" ? "contained" : "outlined"}
            >
              1 Month
            </Button>
            <Button
              onClick={() => {
                setType("This Week");
              }}
              size="small"
              sx={{
                textTransform: "capitalize",
                borderRadius: "32px",
                fontSize: "x-small",
              }}
              variant={type == "This Week" ? "contained" : "outlined"}
            >
              This Week
            </Button>
          </Box>
        </Box>

        <Box display="flex" my={2} alignItems="center">
          <Box flexGrow={1}></Box>
        </Box>

        {loading ? (
          <Box height={335} sx={{ display: "grid", placeContent: "center" }}>
            <Typography variant="body2">Loading data...</Typography>
          </Box>
        ) : !chartData.length ? (
          <Box height={335} sx={{ display: "grid", placeContent: "center" }}>
            <Typography variant="body2">No data available</Typography>
          </Box>
        ) : (
          <ResponsiveContainer width="100%" height={335}>
            <LineChart data={chartData} height={300}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                tickFormatter={(value) => {
                  if (value >= 1000000)
                    return `${(value / 1000000).toFixed(0)}m`;
                  else if (value >= 1000)
                    return `${(value / 1000).toFixed(0)}k`;
                  return value;
                }}
              />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="amount"
                stroke="#8884d8"
                name="Amount"
                dot={false}
              />
              <Line
                type="monotone"
                dataKey="interest"
                stroke="#82ca9d"
                name="Interest"
                dot={false}
              />
              <Line
                type="monotone"
                dataKey="penalties"
                stroke="#ffc658"
                name="Penalties"
                dot={false}
              />
              <Line
                type="monotone"
                dataKey="paymentAmount"
                stroke="#ff7300"
                name="Payment Amount"
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default MyLineChart;
